import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Notiflix from "notiflix";
import PostApiCall from "./Api";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";

const handleSubmit = (event) => {
  event.preventDefault();
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Email: "",
      Mobile: "",
      City: "",
      Message: "",
      newCaptcha: "",
      enteredCaptcha: "",
      CaptchaVerified: false,
      NumRegex: /^0|[0-9]\d*$/,
      MobileRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      SpecialRegex: /[-!$%^&*()_+|~=`'"{}\[\]:\/;<>?,.@#]/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#ed1f24",
    });

    Notiflix.Notify.Init({
      position: "left-bottom",
    });
    this.generateRandomCaptcha()

    document.title =
      "ContactUs - React Academy | Training Institute for ReactJS & React Native in Delhi";
  }

  generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    this.setState({
      captcha: newCaptcha
    })
  };
  isCaptchaValid(enteredCaptcha) {
    return this.state.captcha == enteredCaptcha;
  }

  SendEnquiry() {
    if (this.state.Name != "") {
      if (this.state.Mobile != "") {
        if (this.state.Mobile.length == 10) {
          if (this.state.Email != "") {
            if (this.state.EmailRegex.test(this.state.Email)) {
              if (this.state.City != "") {
                if (this.state.enteredCaptcha != "") {
                  if (this.isCaptchaValid(this.state.enteredCaptcha)) {
                    Notiflix.Loading.Dots("");

                    PostApiCall.postRequest(
                      {
                        name: this.state.Name,
                        email: this.state.Email,
                        mobile: this.state.Mobile,
                        message: this.state.Message,
                        city: this.state.City,
                      },
                      "ReactAcademyContactMailer"
                    ).then((results) =>
                      // const objs = JSON.parse(result._bodyText)
                      results.json().then((obj) => {
                        if (results.status == 200 || results.status == 201) {
                          this.setState({
                            Name: "",
                            Email: "",
                            Mobile: "",
                            Message: "",
                            City: "",
                          });
                          Notiflix.Loading.Remove();
                          window.location.href = "/";
                        }
                      })
                    );
                  } else {
                    Notiflix.Notify.Failure("Please verify Captcha.");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter Captcha.");
                }
              } else {
                Notiflix.Notify.Failure("Tell us where you reside.");
              }
            } else {
              Notiflix.Notify.Failure("Please enter valid Email Address.");
            }
          } else {
            Notiflix.Notify.Failure("Please enter your Email Address.");
          }
        } else {
          Notiflix.Notify.Failure("Please enter valid Mobile / Phone Number.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your Mobile / Phone Number.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your Name.");
    }
  }

  render() {
    return (
      <div className="App">
        <div class="site com-sppagebuilder view-page no-layout no-task itemid-437 en-gb ltr  sticky-header layout-fluid off-canvas-menu-init">
          <div class="off-canvas-menu-wrap">
            <div class="body-innerwrapper">
              <div class="sp-pre-loader">
                <div class="sp-loader-audio-wave"></div>
              </div>
              <header id="sp-header" className="animated menu-fixed">
                <div>
                  <section id="sp-top-bar">
                    <div>
                      <div class="row">
                        <div id="sp-top1" class="col-sm-12 col-md-12">
                          <div class="sp-column ">
                            <div class="col-md-4">
                              <ul
                                class="sp-contact-info"
                                style={{ float: "left" }}>
                                <li
                                  class="sp-contact-address"
                                  style={{ marginTop: "4px" }}>
                                  <i class="fa fa-map-marker"></i>
                                  New Delhi
                                  <span class="tooltiptext">
                                    <h4 class="locationhead">React Academy</h4>
                                    <p>
                                      B 7/1, near Rajouri Garden, Upper Ground,{" "}
                                      <br />
                                      Rajouri Garden, New Delhi, Delhi - 110
                                      027, INDIA.
                                    </p>
                                    {/* <p>D - 200, Third Floor,Mansarover Garden,<br /> New Delhi - 110015. INDIA</p> */}
                                    <p>
                                      {" "}
                                      <a
                                        href="https://maps.app.goo.gl/Cb2rDfHRpPBTpCSv9"
                                        target="_blank"
                                        style={{ color: "black" }}>
                                        <i class="fa fa-map-marker"></i> Get
                                        Direction
                                      </a>
                                    </p>
                                  </span>
                                </li>
                                <li
                                  class="sp-contact-address"
                                  style={{ marginTop: "4px" }}>
                                  <i class="fa fa-map-marker"></i>
                                  Chennai
                                  <span class="tooltiptext2">
                                    <h4 class="locationhead">React Academy</h4>
                                    <p>
                                      621, 3rd Floor, Anna Salai, Chennai - 600
                                      006. INDIA.
                                    </p>
                                    <p>
                                      {" "}
                                      <a
                                        href="https://www.google.com/maps/place/Global+Trendz/@13.0544807,80.2504224,17z/data=!3m1!4b1!4m5!3m4!1s0x3a526646bfbd48bb:0xa13ada1206a37d27!8m2!3d13.0544807!4d80.2526111"
                                        target="_blank"
                                        style={{ color: "black" }}>
                                        <i class="fa fa-map-marker"></i> Get
                                        Direction
                                      </a>
                                    </p>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-8">
                              <ul class="sp-contact-info hidemob">
                                <li class="sp-contact-phone">
                                  <i
                                    class="fa fa-mobile"
                                    aria-hidden="true"></i>{" "}
                                  <a href="tel:917603999622">
                                    +91 76039 99622
                                  </a>
                                </li>
                                <li class="sp-contact-phone">
                                  <i class="fa fa-phone"></i>{" "}
                                  <a href="tel:+91-11-4703 7722">
                                    +91 11 4703 7722
                                  </a>
                                </li>
                                <li class="sp-contact-email">
                                  <i class="fa fa-envelope"></i>{" "}
                                  <a href="mailto:reactacademy@globaltrendz.com">
                                    reactacademy@globaltrendz.com
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.facebook.com/reactacademy.in"
                                    target="_blank">
                                    <i class="fa fa-facebook fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.instagram.com/reactacademy.in"
                                    target="_blank">
                                    <i class="fa fa-instagram fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://www.linkedin.com/company/thereactacademy"
                                    target="_blank">
                                    <i class="fa fa-linkedin fan"></i>
                                  </a>
                                </li>
                                <li style={{ margin: "4px" }}>
                                  <a
                                    href="https://twitter.com/thereactacademy"
                                    target="_blank">
                                    <i class="fa fa-twitter fan"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div class="row">
                    <div id="sp-logo" class="col-xs-6 col-sm-3 col-md-2">
                      <div class="sp-column ">
                        <h1 class="logo">
                          <a href="/">
                            <img
                              class="sp-default-logo"
                              src="logos/logos/REACT ACADEMY.png"
                              alt="reactacademy logo"
                              style={{ width: "60%" }}
                            />
                          </a>
                        </h1>
                      </div>
                    </div>
                    <div id="sp-menu" class="col-xs-6 col-sm-9 col-md-10">
                      <div class="sp-column ">
                        <div class="sp-megamenu-wrapper">
                          {" "}
                          <a id="offcanvas-toggler" href="#">
                            <i class="fa fa-bars"></i>
                          </a>
                          <ul class="sp-megamenu-parent menu-fade hidden-sm hidden-xs">
                            <li class="sp-menu-item sp-has-child current-item active">
                              <a href="/" title="Main page">
                                Home
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a href="/#academy" title="Who We Are">
                                The Academy
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a href="/#trainingprocess" title="How We Teach">
                                Training Process
                              </a>
                            </li>
                            <li class="sp-menu-item sp-has-child">
                              <a href="/#react" title="Technology">
                                React
                              </a>
                            </li>
                            <li class="sp-menu-item">
                              <a href="/#course" title="What We Offer">
                                Courses
                              </a>
                            </li>

                            <li class="sp-menu-item sp-has-child">
                              <a
                                href="/#prerequisites"
                                title="Prior Requirments">
                                Prerequisites
                              </a>
                            </li>

                            <li class="sp-menu-item">
                              <a href="/contact" title="Get In Touch">
                                Contact
                              </a>
                            </li>
                            <li>
                              <a href="/enroll">
                                <button class="btn btn-danger">
                                  Enroll Now
                                </button>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>

              <section id="sp-page-title" className="contactsection">
                <div class="row">
                  <div id="sp-title" class="col-sm-12 col-md-12">
                    <div class="sp-column ">
                      <div
                        class="sp-page-title"
                        style={{
                          backgroundImage:
                            "url(images/banners/contactus-banner.jpg)",
                        }}>
                        <div class="container-fluid">
                          <h2>Contact Us</h2>
                          <ol class="breadcrumb">
                            <li>
                              <a href="/" class="pathway">
                                Home
                              </a>
                            </li>
                            <li class="active">Contact Us</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="sp-main-body">
                <div class="row">
                  <div id="sp-component" class="col-sm-12 col-md-12">
                    <div class="sp-column ">
                      <div id="system-message-container"></div>
                      <div id="sp-page-builder" class="sp-page-builder  page-6">
                        <div class="page-content">
                          <section
                            id="section-id-1577083394"
                            class="sppb-section ">
                            <div class="sppb-row-container">
                              <div class="sppb-row">
                                <div class="sppb-col-sm-12">
                                  <div
                                    id="column-id-1577083395"
                                    class="sppb-addon-container">
                                    <div
                                      id="sppb-addon-1577083396"
                                      class="clearfix">
                                      <div class="sppb-addon sppb-addon-text-block sppb-text-left ">
                                        <div
                                          class="vs_heading tCenter "
                                          style={{ marginTop: "50px" }}>
                                          <h2 class="">Contact US</h2>
                                          <p class="aboutus">
                                            We would love to hear from you! Do
                                            get in touch with us and our team
                                            would be glad to extend our support.
                                          </p>

                                          <div class="icon_heading">
                                            {" "}
                                            <span></span>
                                            <i
                                              class="fa fa-dot-circle-o"
                                              aria-hidden="true"
                                              style={{
                                                color: "#ED1F24",
                                                fontSize: "20px",
                                              }}></i>
                                            <span></span>
                                          </div>
                                        </div>
                                        <div
                                          class="sppb-addon-content"
                                          style={{ marginTop: "3%" }}></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            id="section-id-1577083397"
                            class="sppb-section">
                            <div class="sppb-row-container">
                              <div class="sppb-row">
                                <div class="sppb-col-md-12">
                                  <div
                                    id="column-id-1577083400"
                                    class="sppb-addon-container">
                                    <div
                                      id="sppb-addon-1577083401"
                                      class="clearfix">
                                      <div class="sppb-addon sppb-addon-text-block sppb-text-left ">
                                        <div class="sppb-addon-content">
                                          <div>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <img
                                                  src="logos/logos/REACT ACADEMY.png"
                                                  style={{ width: "30%" }}
                                                  alt="React Academy Logo"
                                                />
                                                <p
                                                  style={{
                                                    fontWeight: " 600",
                                                  }}>
                                                  React Academy -{" "}
                                                  <i>
                                                    A Subsidiary of Global
                                                    Trendz.
                                                  </i>
                                                </p>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="ct_contact_address">
                                                  <h5 class="aboutus">
                                                    Our Locations
                                                  </h5>
                                                  <p>
                                                    <span class="ourlocations">
                                                      New Delhi:
                                                    </span>{" "}
                                                    <br />B 7/1, near Rajouri
                                                    Garden, Upper Ground, <br />
                                                    Rajouri Garden, New Delhi,
                                                    Delhi - 110 027, INDIA.
                                                    <br />
                                                    Phone: +91 11 4703 7722.
                                                    <br />
                                                    <a
                                                      href="https://maps.app.goo.gl/Cb2rDfHRpPBTpCSv9"
                                                      target="_blank">
                                                      <i class="fa fa-map-marker"></i>{" "}
                                                      Get Directions
                                                    </a>
                                                  </p>
                                                  <p>
                                                    <span class="ourlocations">
                                                      Chennai:{" "}
                                                    </span>
                                                    <br /> # 621, 3rd Floor,
                                                    Anna Salai, <br /> Chennai -
                                                    600 006. INDIA. <br />
                                                    Phone: +91 44 4309 9119
                                                    <br />
                                                    <a
                                                      href="https://www.google.com/maps/place/Global+Trendz/@13.0544807,80.2504224,17z/data=!3m1!4b1!4m5!3m4!1s0x3a526646bfbd48bb:0xa13ada1206a37d27!8m2!3d13.0544807!4d80.2526111"
                                                      target="_blank">
                                                      <i class="fa fa-map-marker"></i>{" "}
                                                      Get Directions
                                                    </a>
                                                  </p>

                                                  <p>
                                                    <i
                                                      class="fa fa-mobile"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ED1F24",
                                                        fontSize: "23px",
                                                      }}></i>{" "}
                                                    &nbsp; Call Us On{" "}
                                                    <a
                                                      href="tel:+91 76039 99622"
                                                      style={{
                                                        color: "black",
                                                      }}>
                                                      +91 76039 99622
                                                    </a>
                                                    <br />
                                                    <i
                                                      class="fa fa-envelope"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ED1F24",
                                                      }}></i>{" "}
                                                    &nbsp;Write to us at{" "}
                                                    <a
                                                      href="reactacademy@globaltrendz.com"
                                                      style={{
                                                        color: "black",
                                                      }}>
                                                      reactacademy@globaltrendz.com
                                                    </a>{" "}
                                                    <br />
                                                    <i
                                                      class="fa fa-globe"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ED1F24",
                                                      }}></i>{" "}
                                                    &nbsp; Website:{" "}
                                                    <a
                                                      href="www.reactacademy.in"
                                                      style={{
                                                        color: "black",
                                                      }}>
                                                      www.reactacademy.in
                                                    </a>
                                                  </p>
                                                  <p
                                                    style={{
                                                      marginBottom: "20px",
                                                    }}>
                                                    <span
                                                      class="aboutus"
                                                      style={{
                                                        marginBottom: " -5px",
                                                      }}>
                                                      Follow Us On:
                                                    </span>{" "}
                                                    <br />
                                                    <ul class="social-icons">
                                                      <li>
                                                        <a
                                                          href="https://www.facebook.com/reactacademy.in"
                                                          target="_blank">
                                                          <i class="fa fa-facebook fan"></i>
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          href="https://www.instagram.com/reactacademy.in"
                                                          target="_blank">
                                                          <i class="fa fa-instagram fan"></i>
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          href="https://www.linkedin.com/company/thereactacademy"
                                                          target="_blank">
                                                          <i class="fa fa-linkedin fan"></i>
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          href="https://twitter.com/thereactacademy"
                                                          target="_blank">
                                                          <i class="fa fa-twitter fan"></i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </p>
                                                </div>
                                              </div>

                                              <div class="col-md-6">
                                                <div
                                                  id="column-id-1577083398"
                                                  class="sppb-addon-container">
                                                  <div
                                                    id="sppb-addon-1577083399"
                                                    class="clearfix">
                                                    <div class="sppb-addon sppb-addon-module ">
                                                      <div class="sppb-addon-content">
                                                        <div class="vs_ajax_form">
                                                          <form
                                                            onSubmit={
                                                              handleSubmit
                                                            }
                                                            id="1">
                                                            <div class="row">
                                                              <p class="aboutus">
                                                                Write to us.
                                                              </p>
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">
                                                                  <input
                                                                    class="required"
                                                                    type="text"
                                                                    placeholder="Tell us your Name"
                                                                    value={
                                                                      this.state
                                                                        .Name
                                                                    }
                                                                    onChange={(
                                                                      name
                                                                    ) => {
                                                                      this.setState(
                                                                        {
                                                                          Name: name
                                                                            .target
                                                                            .value,
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                  <i
                                                                    class="fa fa-user"
                                                                    aria-hidden="true"></i>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">
                                                                  <input
                                                                    class="required"
                                                                    type="text"
                                                                    name="phone"
                                                                    placeholder="Phone / Mobile Number"
                                                                    value={
                                                                      this.state
                                                                        .Mobile
                                                                    }
                                                                    onChange={(
                                                                      mobile
                                                                    ) => {
                                                                      if (
                                                                        this.state.MobileRegex.test(
                                                                          mobile
                                                                            .target
                                                                            .value
                                                                        ) &&
                                                                        mobile
                                                                          .target
                                                                          .value
                                                                          .length <=
                                                                        10
                                                                      ) {
                                                                        this.setState(
                                                                          {
                                                                            Mobile:
                                                                              mobile
                                                                                .target
                                                                                .value,
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  />{" "}
                                                                  <i
                                                                    class="fa fa-envelope"
                                                                    aria-hidden="true"></i>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">
                                                                  <input
                                                                    class="required"
                                                                    type="text"
                                                                    name="email"
                                                                    placeholder="Email"
                                                                    value={
                                                                      this.state
                                                                        .Email
                                                                    }
                                                                    onChange={(
                                                                      email
                                                                    ) => {
                                                                      this.setState(
                                                                        {
                                                                          Email:
                                                                            email
                                                                              .target
                                                                              .value,
                                                                        }
                                                                      );
                                                                    }}
                                                                  />{" "}
                                                                  <i
                                                                    class="fa fa-envelope"
                                                                    aria-hidden="true"></i>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">
                                                                  <input
                                                                    class="required"
                                                                    type="text"
                                                                    name="city"
                                                                    placeholder="City - Where do you live?"
                                                                    value={
                                                                      this.state
                                                                        .City
                                                                    }
                                                                    onChange={(
                                                                      city
                                                                    ) => {
                                                                      this.setState(
                                                                        {
                                                                          City: city
                                                                            .target
                                                                            .value,
                                                                        }
                                                                      );
                                                                    }}
                                                                  />{" "}
                                                                  <i
                                                                    class="fa fa-building-o"
                                                                    aria-hidden="true"></i>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">
                                                                  <textarea
                                                                    class="required"
                                                                    name="message"
                                                                    row="1"
                                                                    placeholder="Would you like any other information?"
                                                                    value={
                                                                      this.state
                                                                        .Message
                                                                    }
                                                                    onChange={(
                                                                      message
                                                                    ) => {
                                                                      this.setState(
                                                                        {
                                                                          Message:
                                                                            message
                                                                              .target
                                                                              .value,
                                                                        }
                                                                      );
                                                                    }}></textarea>{" "}
                                                                  <i
                                                                    class="fa fa-pencil-square-o"
                                                                    aria-hidden="true"></i>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <div class="form_item">

                                                                  <div className="d-flex">
                                                                    <div className="captcha-box">
                                                                      <input
                                                                        type="text"
                                                                        value={this.state.captcha}
                                                                        disabled
                                                                        className="form-control"
                                                                      />
                                                                      <div
                                                                        className="captcha"
                                                                        onClick={this.generateRandomCaptcha.bind()}
                                                                      >
                                                                        <span class="glyphicon glyphicon-refresh"></span>
                                                                      </div>
                                                                      <div className="form-group col-lg-4 mb-4">
                                                                        <input
                                                                          type="text"
                                                                          value={this.state.enteredCaptcha}
                                                                          onChange={(e) => {
                                                                            this.setState({
                                                                              enteredCaptcha: e.target.value.toUpperCase()
                                                                            })
                                                                          }
                                                                          }
                                                                          className="form-control"
                                                                          placeholder="Enter Captcha *"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div class="row">
                                                              <div class="col-sm-12 col-xs-12">
                                                                <button
                                                                  class="btn btn-danger2 "
                                                                  onClick={this.SendEnquiry.bind(
                                                                    this
                                                                  )}>
                                                                  Send us your
                                                                  Enquiry
                                                                </button>
                                                              </div>
                                                            </div>
                                                            <div class="message"></div>
                                                          </form>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>

                          <section
                            id="section-id-1577083363"
                            class="sppb-section  event_row"
                            style={{ marginTop: "3rem" }}>
                            <div class="container-fluid">
                              <div class="sppb-row">
                                <div class="sppb-col-sm-6">
                                  <div
                                    id="column-id-1577083364"
                                    class="sppb-addon-container">
                                    <div
                                      id="sppb-addon-1577083365"
                                      class="clearfix">
                                      <div class="course_countdown">
                                        <div class="info">
                                          <h3>
                                            <a href="courses/course-detail.html">
                                              Become a Full stack React
                                              Developer{" "}
                                            </a>
                                          </h3>
                                          <span class="author">
                                            Are you ready to Learn React?
                                          </span>
                                          <h2>Register yourself now</h2>
                                          <div class="des">
                                            <p>
                                              Hurry...! join the React Bootcamp
                                              session that suits you the best.
                                              Our new sessions begin every week.
                                            </p>
                                          </div>
                                        </div>
                                        <div class="time">
                                          <ul style={{ paddingLeft: "5%" }}>
                                            <h1 style={{ color: " #ED1F24" }}>
                                              Call{" "}
                                              <a href="tel:917603999622">
                                                +91 76039 99622
                                              </a>
                                            </h1>
                                          </ul>
                                        </div>
                                        <div class="more">
                                          {" "}
                                          <a href="/enroll">Enroll Now</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="sppb-col-sm-6">
                                  <div
                                    id="column-id-1577083366"
                                    class="sppb-addon-container">
                                    <div
                                      id="sppb-addon-1577083367"
                                      class="clearfix">
                                      <div class="sppb-addon sppb-addon-single-image sppb-text-left ">
                                        <div class="sppb-addon-content">
                                          <div class="sppb-addon-single-image-container">
                                            <div class="row">
                                              <div
                                                id="sppb-addon-1577083391"
                                                class="clearfix">
                                                <div class="vs_heading tCenter prequist">
                                                  <h2
                                                    class=""
                                                    style={{
                                                      color: " white",
                                                      textAlign: " right",
                                                    }}>
                                                    Prerequisites
                                                  </h2>
                                                  <p
                                                    class="aboutus"
                                                    style={{
                                                      textAlign: "right",
                                                      color: "white",
                                                    }}>
                                                    A few prerequisites before
                                                    you join our Bootcamp
                                                    Sessions.
                                                  </p>
                                                  <div class="icon_heading prehrline">
                                                    {" "}
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          "white",
                                                      }}></span>
                                                    <i
                                                      class="fa fa-dot-circle-o"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ED1F24",
                                                        fontSize: "20px",
                                                      }}></i>
                                                    <span
                                                      style={{
                                                        backgroundColor:
                                                          " white",
                                                      }}></span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">Knowledge</h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        "right!important",
                                                    }}>
                                                    You must have a good
                                                    knowledge of HTML5, CSS &
                                                    JavaScript and a little
                                                    experience in developing Web
                                                    Apps would be an added
                                                    advantage.
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">
                                                  Personal Laptop
                                                </h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        "right!important",
                                                    }}>
                                                    You must bring your personal
                                                    laptop along with you. This
                                                    is because as part of the
                                                    training we would want you
                                                    to develop applications for
                                                    testing and deployment.
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-12">
                                                <h4 class="prequ">
                                                  Setup on your Laptop
                                                </h4>
                                                <div class="prequpara">
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        " right!important",
                                                    }}>
                                                    A latest version of the
                                                    React Libraries, Node JS and
                                                    Visual Studio Code is what
                                                    you would need. On
                                                    Enrollment you will receive
                                                    a list of things to be
                                                    installed.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="sp-cmc">
                <div class="container">
                  <div class="row">
                    <div id="sp-feature" class="col-sm-12 col-md-12">
                      <div class="sp-column ">
                        <div class="sp-module ">
                          <h2>
                            Our Training & Internships Empowers your React
                            Skills
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <footer id="sp-footer" className="webfooter">
                <div class="container-fluid">
                  <div class="row">
                    <div id="sp-footer1" class="col-sm-6 col-md-6">
                      <div class="sp-column tLeft">
                        <span class="sp-copyright">
                          ©{" "}
                          <script type="text/javascript">
                            var year = new
                            Date();document.write(year.getFullYear());
                          </script>{" "}
                          React Academy - A subsidiary of{" "}
                          <a href="globaltrendz.com">Global Trendz</a>. All
                          Rights Reserved
                        </span>
                      </div>
                    </div>
                    <div id="sp-footer2" class="col-sm-6 col-md-6">
                      <div class="sp-column tRight">
                        <ul class="social-icons">
                          <li>
                            <a
                              href="https://www.facebook.com/reactacademy.in"
                              target="_blank">
                              <i class="fa fa-facebook fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/reactacademy.in"
                              target="_blank">
                              <i class="fa fa-instagram fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/thereactacademy"
                              target="_blank">
                              <i class="fa fa-linkedin fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/thereactacademy"
                              target="_blank">
                              <i class="fa fa-twitter fan"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
              <footer id="sp-footer" className="mobfooter">
                <div class="container-fluid">
                  <div class="row">
                    <div id="sp-footer2" class="col-sm-6 col-md-6">
                      <div class="sp-column tRight">
                        <ul class="social-icons">
                          <li>
                            <a
                              href="https://www.facebook.com/reactacademy.in"
                              target="_blank">
                              <i class="fa fa-facebook fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/reactacademy.in"
                              target="_blank">
                              <i class="fa fa-instagram fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/thereactacademy"
                              target="_blank">
                              <i class="fa fa-linkedin fan"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/thereactacademy"
                              target="_blank">
                              <i class="fa fa-twitter fan"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="sp-footer1" class="col-sm-6 col-md-6">
                      <div class="sp-column tLeft">
                        <span class="sp-copyright">
                          ©{" "}
                          <script type="text/javascript">
                            var year = new
                            Date();document.write(year.getFullYear());
                          </script>{" "}
                          React Academy - A subsidiary of{" "}
                          <a href="globaltrendz.com">Global Trendz</a>.<br />{" "}
                          All Rights Reserved
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
          <div class="offcanvas-menu">
            {" "}
            <a href="#" class="close-offcanvas">
              <i class="fa fa-remove"></i>
            </a>
            <div class="offcanvas-inner">
              <div class="sp-module ">
                <div class="sp-module-content">
                  <ul class="nav menu ">
                    <li class="item-437  current active deeper parent">
                      <a href="/" title="Main page">
                        {" "}
                        Home
                      </a>
                    </li>
                    <li class="item-518  deeper parent ">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass("offcanvas");

                          window.location.href = "/#academy";
                        }}
                        title="Who We Are">
                        {" "}
                        The Academy
                      </a>
                    </li>

                    <li class="item-520">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass("offcanvas");

                          window.location.href = "/#trainingprocess";
                        }}
                        // href="/#trainingprocess"
                        title="How We Teach">
                        {" "}
                        Training Process
                      </a>
                    </li>
                    <li class="item-522  deeper parent">
                      <a
                        title="Technology"
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass("offcanvas");

                          window.location.href = "/#react";
                        }}>
                        {" "}
                        React
                      </a>
                    </li>
                    <li class="item-521  deeper parent">
                      <a
                        title="What We Offer"
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass("offcanvas");

                          window.location.href = "/#course";
                        }}>
                        {" "}
                        Courses
                      </a>
                    </li>
                    <li class="item-528  deeper parent">
                      <a
                        onClick={() => {
                          $(".off-canvas-menu-init").removeClass("offcanvas");

                          window.location.href = "/#prerequisites";
                        }}
                        title="PRIOR REQUIRMENTS">
                        Prerequisites
                      </a>
                    </li>

                    <li class="item-584">
                      <a href="/contact" title="Get In Touch">
                        {" "}
                        Contact
                      </a>
                    </li>

                    <li class="sp-contact-phone">
                      <a href="tel:917603999622">+91 76039 99622</a>
                    </li>
                    <li class="sp-contact-phone">
                      {" "}
                      <a href="tel:+91-11-4703 7722">+91 11 4703 7722</a>
                    </li>
                    <li class="sp-contact-email">
                      {" "}
                      <a href="mailto:reactacademy@globaltrendz.com">
                        reactacademy@globaltrendz.com
                      </a>
                    </li>
                    <li>
                      <a href="/enroll">
                        <button class="btn btn-danger">Enroll Now</button>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <a href="javascript:void(0)" class="back_to_top">
            <i class="fa fa-angle-up"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Contact;
